import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanks(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/bank/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchBanksByRm(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/bank/listRm', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchBank(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/bank`, {params:queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addBank(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/bank', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteAuthorisedPerson(ctx, queryParams) {
        return new Promise((resolve, reject) => {
            axiosIns.delete('/bank/authorization', {params:queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
  },
}

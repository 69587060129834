import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useBanksList() {

    //User toast
    const toast = useToast()

    const refBankListTable = ref(null)

    const tableColumns = [
        {key: 'id', label:'Bank ID', sortable: true},
        {key: 'name',stickyColumn: true,  label: 'Bank Name',sortable: true},
        {key: 'date_of_agreement', label: 'Date of Referral Agreement'},
        {key: 'date_of_termination', label: 'Date of Termination'},
        {key: 'remarks', label: 'Remarks'},
        {key: 'updated_at', label: 'Last Update'},
        {key: 'actions'},
    ]

    const tableBankRMColumns = [
        {key: 'name', stickyColumn: true, label: 'Bank Name', sortable: true},
        {key: 'authorised_signers', label: 'Authorised Signers'},
        {key: 'authorized_representitives', label: 'Authoried Representitives'},
        {key: 'authorized_admin', label: 'Authoried Admin'},
        {key: 'actions'},
    ]

    const userColumns = [
        {key: 'name', label: 'Rms'},
    ]

    const userData = localStorage.getItem('userData');
    const userFilter = ref(userData ? JSON.parse(userData).id : null)

    const perPage = ref(10)
    const totalBanks = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refBankListTable.value ? refBankListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBanks.value,
        }
    })

    const refetchData = () => {
        refBankListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, userFilter], () => {
        refetchData()
    })

    const fetchBanks = (ctx, callback) => {
        store.dispatch('app-bank/fetchBanks',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            user_id: userFilter.value
        })
        .then(response =>{
            const {banks, total} = response.data
            callback(banks)
            totalBanks.value = total
            
        })
        .catch((error) => {
            console.log(error)
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching bank list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchBanks,
        tableColumns,
        perPage,
        currentPage,
        totalBanks,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBankListTable,
        ability,
        refetchData,
        userColumns,
        tableBankRMColumns,
        userFilter,
    }

}